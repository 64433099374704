import React from "react"
import ProductPage from "../../layouts/product-page"

const model = {
  eng: {
    id: "73g",
    model: "P13  7’’  3G",
    os: "Android  O（Go edition)",
    chipset: "MT8321 Quad-core 1.3GHz",
    band: "gsm: 850 / 900 / 1800 / 1900MHz | umts: 850/900/1900/2100MHz",
    display: "7.0“,TN,600*1024 (Landscape)",
    memory: "1GB+16GB(Compatible to 1GB+16GB",
    dimensions: "189.5*111*9.15mm, 245g",
    cameras: "front: 0.3MP FF | rear: 2MP FF",
    connectivity: "WiFi 802.11 b/g/n, BT4.2, FM(RDS), GPS",
    sensor: "G-sensor",
    interface: "Micro USB , 3.5mm jack , TF Card Slot, OTG",
    battery: "2580mAh，Non-removable",
    cmf: "Raw material",
    accessories: "default: Charger 5V/1A, USB cable, QG, SIM card slot",
    image:
      "https://lh3.googleusercontent.com/pw/ACtC-3dCjZkGgSPnNsOIt837lxUfpNx0xI9oAgjiT1_tFf3h9zBD0TN7tM3dyZYdmq80_gfFDASn-qgBCe77OIhiDXBLW_PisgjjS0sb7UXa6egUTxVDZn-oR7gMrLHz27js8LxLxQmPE3CMp3C7_hupRCs=d",
  },
  esp: {
    id: "73g",
    model: "P13  7’’  3G",
    os: "Android  O（Go edition)",
    chipset: "MT8321 Quad-core 1.3GHz",
    band: "gsm: 850 / 900 / 1800 / 1900MHz | umts: 850/900/1900/2100MHz",
    display: "7.0“,TN,600*1024 (Landscape)",
    memory: "1GB+16GB(Compatible to 1GB+16GB",
    dimensions: "189.5*111*9.15mm, 245g",
    cameras: "front: 0.3MP FF | rear: 2MP FF",
    connectivity: "WiFi 802.11 b/g/n, BT4.2, FM(RDS), GPS",
    sensor: "G-sensor",
    interface: "Micro USB , 3.5mm jack , TF Card Slot, OTG",
    battery: "2580mAh，Non-removable",
    cmf: "Raw material",
    accessories: "default: Charger 5V/1A, USB cable, QG, SIM card slot",
    image:
      "https://lh3.googleusercontent.com/pw/ACtC-3dCjZkGgSPnNsOIt837lxUfpNx0xI9oAgjiT1_tFf3h9zBD0TN7tM3dyZYdmq80_gfFDASn-qgBCe77OIhiDXBLW_PisgjjS0sb7UXa6egUTxVDZn-oR7gMrLHz27js8LxLxQmPE3CMp3C7_hupRCs=d",
  },
}
export default () => <ProductPage model={model} />
